<template>
  <v-container fill-height fluid style="height: 100%">
    <v-row class="text-center">
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title class="justify-center">
            Wir benötigen Ihre Zustimmung, um "Google Maps" zu laden!
          </v-card-title>
          <v-card-text>
            Wir nutzen einen Drittanbieterdienst, um Karteninhalte einzubetten.
            Bitte prüfen Sie die Details und akzeptieren Sie den Dienst, um
            diese Karte anzusehen.
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn @click="openCookieDialog"> MEHR INFORMATION </v-btn>
            <v-btn
              @click="acceptGoogleMapsCookie"
              color="primary"
              class="ml-10"
            >
              ZUSTIMMEN
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    acceptGoogleMapsCookie() {
      loginNew.setServiceAllowed("S1pcEj_jZX", true);
    },

    openCookieDialog() {
      loginNew.showCookieDialog();
    },
  },
  mounted() {
    if (loginNew.serviceAllowed("S1pcEj_jZX")) {
      this.$router.push("/").catch((e) => {
        this.$store.commit("showDialog", {
          title: "Error navigating",
          content: e.message,
        });
      });
      return;
    }
  },
};
</script>
